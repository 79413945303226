import { NextRouter, useRouter } from 'next/router';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTenantContext } from '../../context/TenantContext';
import withAuth from '../../hoc/withAuth';
import LoginContent from '../LoginContent';
import MyContainer from '../MyContainer';

export const getLandingUrl = (router: NextRouter) => {
    if (typeof window === 'undefined') {
        return '';
    }
    // when trying to access a nested page we want to redirect the user to it after logging in
    return router.query.next
        ? `${window.location.origin}${router.query.next as string}`
        : `${window.location.origin}/${router.locale}/my`;
};

const Login: FC = () => {
    const { brandConfig } = useTenantContext();
    const router = useRouter();

    return (
        <MyContainer>
            <LoginContent
                title={
                    <FormattedMessage
                        defaultMessage="Inloggen in Mijn {island}"
                        values={{ island: brandConfig.name }}
                    />
                }
                description={
                    <FormattedMessage defaultMessage="Je ontvangt een e-mail met een persoonlijke link naar je account" />
                }
                landingUrl={getLandingUrl(router)}
                brandName={brandConfig.name}
            />
        </MyContainer>
    );
};

export default withAuth(Login, { onSucces: () => `/my` });
